import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div
      className={"fixed-top"}
      style={{
        zIndex: "1",
        width: "100%",
        top: "80px",
        borderTop: "1px solid var(--whiteGreen)",
        opacity: "0.4",
      }}
    ></div>

    {/* <div className="_404"> */}
    <div
      className={"parallax1"}
      style={{
        minHeight: "85vh",
        paddingTop: "60px",
        paddingBottom: "20px",
        fontFamily: "'Rubik', sans-serif !important",
      }}
    >
      <div className="container px-5 pt-5">
        <h1 className="mt-5">404: Not Found</h1>
        <p className="">You just hit a route that doesn&#39;t exist...</p>
      </div>
    </div>

    <div
      style={{
        margin: "0",
        padding: "0",
        width: "100%",
        borderBottom: "1px solid var(--darkGreen)",
        opacity: "0.4",
      }}
    ></div>
  </Layout>
)

export default NotFoundPage
